import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class VendorSubscriptionsService
	extends ExtendableRefBookService<Subscription<exist>>
	implements AbstractCRUDService {

	readonly create = this.update;
	readonly read = this.load;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.product');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.product');
	}

	override load(): Observable<ReadResponse<Subscription<exist>[]>> {
		return this.api.loader<ReadResponse<Subscription<exist>[]>>(
			'VendorsSubscriptions.Read', {}, this.loading$, this.errorNotifier
		)
			.pipe(map((resp) => {
				sortData(resp.Data, 'name');
				return super.process(resp);
			}));
	}

	update(s: Subscription): Observable<CreateResponse<[Subscription<exist>]>> {
		const notify = (response: CreateResponse<[Subscription<exist>]>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					s.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: s.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};

		return this.api.loader<CreateResponse<[Subscription<exist>]>>(
			`VendorsSubscriptions.${s.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [s] } }, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Subscription>> {
		const params: DeleteRequest<Subscription<exist>> = {
			Data: {
				Ids: [id]
			}
		};
		const notify = (response: DeleteResponse<Subscription>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Subscription>>(
			'VendorsSubscriptions.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

}
