import { Injectable } from '@angular/core';
import { AlarisProfileService } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class OwnerService {

	constructor(
		private readonly profile: AlarisProfileService
	) {
	}

	get is(): boolean {
		return !!this.profile.user.details.isOwner;
	}
}
