<alaris-dialog [closeAction]="closeAction.bind(this)"
			   [confirmAction]="confirmAction.bind(this)"
			   [confirmButtonText]="editable ? 'gl.confirm' : ''"
			   [loading$]="loading$"
			   [size]="'auto'"
			   class="dialog">
	<ng-container slot="header">
		<h3>{{
				subscribed.length > 0
					? ('partners.subscriptionsDialog.title' | translate)
					: ('partners.available'  | translate)
			}}</h3>
	</ng-container>
	<ng-container slot="content">
		@if (subscribed.length > 0) {
			<alaris-tabs #tabs [underline]="false" class="alaris-tabs">
				<alaris-tab [label]="'partners.subscribed' | translate" tabId="subscribed">
					@if (tabs.activeTab?.tabId === 'subscribed') {
						<ng-container *ngTemplateOutlet="table; context: {rows: subscribedTRows, tab: 'subscribed'}">
						</ng-container>
					}
				</alaris-tab>
				<alaris-tab [label]="'partners.available'  | translate" tabId="available">
					@if (tabs.activeTab?.tabId === 'available') {
						<ng-container *ngTemplateOutlet="availableTable"></ng-container>
					}
				</alaris-tab>
			</alaris-tabs>
		} @else {
			<ng-container *ngTemplateOutlet="availableTable"></ng-container>
		}
		<div class="result">
			<div class="selected">
				@if (deltaSubscribe.length > 0) {
					<div class="list">
						<strong>{{ 'partners.subscriptionsDialog.forSubscribe' |  translate }}:</strong>
						@for (sub of deltaSubscribe; track $index) {
							<alaris-tag [fill]="sub.type === SubscriptionType.PACK"
										class="tag"
										mode="info">
								{{ sub.name }}
								<alaris-icon (click)="removeFromDelta(sub, 'subscribe')"
											 class="pointer" data-testid="subscribeDeltaBtn"
											 name="icon-close">
								</alaris-icon>
							</alaris-tag>
						}

					</div>
				}
				@if (deltaUnsubscribe.length > 0) {
					<div class="list">
						<strong>{{ 'partners.subscriptionsDialog.forUnsubscribe' |  translate }}:</strong>
						@for (unsub of deltaUnsubscribe; track $index) {
							<alaris-tag [fill]="unsub.type === SubscriptionType.PACK"
										class="tag text-truncate"
										mode="warning">
								{{ unsub.name }}
								<alaris-icon (click)="removeFromDelta(unsub, 'unsubscribe')"
											 class="pointer" data-testid="unsubscribeDeltaBtn"
											 name="icon-close">
								</alaris-icon>
							</alaris-tag>
						}
					</div>
				}
			</div>
		</div>
		<alaris-alert class="note">
			{{ 'partners.subscriptionsDialog.note' |  translate }}
			@if (checkUnsubscribePacks()) {
				{{ 'partners.subscriptionsDialog.packNote' |  translate }}
			}
		</alaris-alert>
	</ng-container>
</alaris-dialog>

<ng-template #availableTable>
	@if (available.length === 0) {
		<app-empty-table (newSubscriptions)="navigate()" [disabled]="!editable"
						 [showImg]="false"
						 type="subscriptions">
		</app-empty-table>
	}
	@if (available.length > 0) {
		<ng-container *ngTemplateOutlet="table; context: {rows: availableTRows, tab: 'available'}"></ng-container>
	}
</ng-template>

<ng-template #table let-actions="actions" let-rows="rows" let-tab="tab">
	<alaris-table (filterChange)="applyFilter($event)"
				  (sortingChange)="applySorting($event)"
				  [filters]="filters"
				  [sorting]="sorting"
				  [tHeaders]="tHeaders"
				  [tRows]="rows"
				  [templates]="{
				  			name: tab === 'subscribed' ? nameMinus : namePlus,
				  			currencyId, type, trafficType, packSettings, activeTill
				  }"
				  alarisOverflowFader class="alaris-table">
	</alaris-table>
</ng-template>

<ng-template #namePlus [alarisCellCtx]="available[0]" let-data="data">
	<div (click)="subscribe(data)" [class.pointer]="editable" class="name-column" data-testid="subscribeBtn">
		<div class="name">
			{{ data.name }}
			@if (data.type === SubscriptionType.PACK && (data | as: 'any').packSettings?.isPromo) {
				({{ ('subscriptions.promo' | translate) }})
			}
		</div>
		@if (editable) {
			<div class="icon">
				<alaris-icon name="icon-plus"></alaris-icon>
			</div>
		}
	</div>
</ng-template>

<ng-template #nameMinus [alarisCellCtx]="available[0]" let-data="data">
	<div (click)="unsubscribe(data)" [class.pointer]="editable" class="name-column" data-testid="unsubscribeBtn">
		<div class="name">
			{{ data.name }}
		</div>
		@if (editable) {
			<div class="icon">
				<alaris-icon name="icon-minus"></alaris-icon>
			</div>
		}
	</div>
</ng-template>

<ng-template #currencyId [alarisCellCtx]="available[0]" let-data="data">
	{{ (data.currencyId ?? 0 | alarisCurrency)?.name }}
</ng-template>

<ng-template #type [alarisCellCtx]="available[0]" let-data="data">
	{{ 'enums.' + data.type | translate }}
</ng-template>

<ng-template #trafficType [alarisCellCtx]="available[0]" let-data="data">
	<div class="trafficType">
		<alaris-icon [name]="cu.icon(data.trafficType)"></alaris-icon>
		<div>
			@if (data.trafficType !== TrafficType.VIBER) {
				{{ cu.name(data.trafficType) | translate }}
			}
			@if (data.trafficType === TrafficType.VIBER) {
				{{ cu.purpose(data.trafficType, data.settings?.viberSettings?.messagePurpose) | translate }}
			}
		</div>
	</div>
</ng-template>

<ng-template #packSettings let-data="data">
	@if (data.packSettings) {
		<div class="cost">
			@let rest = data.messagesTotal - (data.messagesUsed ?? 0) - (data.messagesLocked ?? 0) ;
			<div>{{ data.packSettings.packPrice | currency: bs.currencyCode }}</div>
			<div>( {{ rest + ' ' + ('gl.of' | translate) + ' ' + data.messagesTotal }} )</div>
		</div>
	} @else {
		-
	}
</ng-template>

<ng-template #activeTill let-data="data" let-key="key">
	{{ getActiveTillDate(data)  | timeAgo:'':true }}
</ng-template>
