import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService
} from '@campaign-portal/components-library';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

@Injectable({
	providedIn: 'root'
})
// eslint-disable-next-line max-len
export class SubscriptionGroupsService extends ExtendableRefBookService<SubscriptionGroup<exist>> implements AbstractCRUDService {
	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.subscriptionGroups');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.subscriptionGroups');
	}

	override load(): Observable<ReadResponse<SubscriptionGroup<exist>[]>> {
		const params: RPCRequestParams = {
			Sorting: [{
				Field: 'lastUpdated',
				Dir: SortDirection.DESC
			}]
		};
		return this.api.loader<ReadResponse<SubscriptionGroup<exist>[]>>(
			'SubscriptionGroups.Read',
			params,
			this.loading$,
			this.errorNotifier
		)
			.pipe(tap((resp) => {
				super.process(resp);
			}));
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<SubscriptionGroup<exist>[]>> {
		return this.api.loader<ReadResponse<SubscriptionGroup<exist>[]>>(
			'SubscriptionGroups.Read',
			params,
			this.loading$,
			this.errorNotifier
		);
	}

	update(group: SubscriptionGroup): Observable<CreateResponse<SubscriptionGroup<exist>>> {
		const notify = (response: CreateResponse<SubscriptionGroup<exist>>): void => {
			const message = this.langService.translate(
				group.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity,
					name: group.name ?? ''
				});
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<SubscriptionGroup<exist>>>(
			`SubscriptionGroups.${group.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [group] } }, this.loading$, this.errorNotifier, notify)
			.pipe(
				tap(() => {
					this.refresh$.next();
				})
			);
	}

	delete(id: Id<exist>): Observable<DeleteResponse<SubscriptionGroup>> {
		const params: DeleteRequest<SubscriptionGroup<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<SubscriptionGroup>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<SubscriptionGroup>>(
			'SubscriptionGroups.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(
			tap(() => {
				this.refresh$.next();
			})
		);
	}
}
