import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
	AlarisApiService,
	AlarisLanguageService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { exist } from '@campaign-portal/namespace/common/id';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';

@Injectable({
	providedIn: 'root'
})
export class ChannelsService extends ExtendableRefBookService<Channel<exist>> {

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
	
	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get title(): string {
		return this.langService.translate('notifications.titles.channel');
	}

	override load(): Observable<ReadResponse<Channel<exist>[]>> {
		return this.api.loader<ReadResponse<Channel<exist>[]>>('Channels.Read', {}, this.loading$, this.errorNotifier)
			.pipe(map((resp) => {
				sortData(resp.Data, 'name');
				return super.process(resp);
			}));
	}
}
