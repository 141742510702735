import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import {
	AlarisAuthService,
	AlarisBalanceComponent,
	AlarisContentLayoutService,
	AlarisMenuSidebarComponent,
	AlarisNavbarComponent,
	AlarisProfileComponent,
	AlarisProfileService,
	AlarisRootContentComponent,
	AlarisSystemTimeComponent,
	LAYOUT_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { OwnerService } from '@helpers/services/owner.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { routerLinks } from '@helpers/shared/router-links.const';
import { RepoService } from '@helpers/repo/repo.service';
import { AuthGuardService } from '../../auth/auth.guard';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

function mainLayoutServiceFact(elementRef: ElementRef, router: Router): AlarisContentLayoutService {
	return new AlarisContentLayoutService(elementRef.nativeElement, router);
}

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: LAYOUT_SERVICE_INJECTOR, useFactory: mainLayoutServiceFact, deps: [ElementRef, Router] }],
	animations: [
		trigger('mainLayoutChanged', [
			transition('* <=> *', [
				style({ height: 0, opacity: 0 }),
				animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*', opacity: 1 }))
			])
		]),
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ width: 0, opacity: 0 }),
				animate('150ms ease-out', style({ width: 240, opacity: 1 }))
			]),
			transition(':leave', [
				style({ width: 240, opacity: 1 }),
				animate('150ms ease-in', style({ width: 0, opacity: 0 }))
			])
		])
	],
	standalone: true,
	imports: [
		AlarisMenuSidebarComponent,
		AlarisSystemTimeComponent,
		AlarisRootContentComponent,
		AlarisNavbarComponent,
		AlarisBalanceComponent,
		AlarisProfileComponent,
		RouterOutlet,
		AsyncPipe,
		TranslateModule
	]
})
export class MainLayoutComponent implements OnInit {

	readonly menuList = routerLinks;
	dynamicTitle = '';

	readonly loading$ = this.repo.loaded$.pipe(map((loaded) => {
		return !loaded;
	}));

	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		@Inject(LAYOUT_SERVICE_INJECTOR) public readonly layoutService: AlarisContentLayoutService,
		public readonly isOwner: OwnerService,
		public readonly auth: AlarisAuthService,
		public readonly authGuard: AuthGuardService,
		private readonly repo: RepoService,
		private readonly router: Router,
		private readonly profile: AlarisProfileService
	) {

		this.router.events.pipe(
			map(e => {
				if ( e instanceof NavigationStart ) {
					this.dynamicTitle = this.router.getCurrentNavigation()?.extras?.state?.title;
				}
				return e;
			}),
			takeUntilDestroyed(this.destroyedRef))
			.subscribe();
	}

	ngOnInit(): void {
		if ( !this.isOwner.is ) {
			this.menuList.settings.children = this.menuList.settings.children?.filter(r => r.path !== 'mccmnc');
		}

		if ( !(this.isOwner.is || this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_R])) ) {
			delete this.menuList.rates;
		}
	}

	prepareInterfaceRoute(outlet: RouterOutlet): boolean {
		return outlet &&
			outlet.activatedRouteData &&
			outlet.activatedRouteData.animationState;
	}

}
