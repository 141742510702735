import { Pipe, PipeTransform } from '@angular/core';

import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';

import { RepoService, RepoType } from './repo.service';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';

@Pipe({
	name: 'channel',
	standalone: true
})
export class ChannelPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): Channel | null {
		return this.repo.transform(id, RepoType.CHANNELS);
	}
}

@Pipe({
	name: 'partner',
	standalone: true
})
export class PartnerPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): Partner | null {
		return this.repo.transform(id, RepoType.PARTNER);
	}
}

@Pipe({
	name: 'product',
	standalone: true
})
export class ProductPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): Subscription | null {
		return this.repo.transform(id, RepoType.PRODUCT);
	}
}

@Pipe({
	name: 'cCompany',
	standalone: true
})
export class ContractCompanyPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): ContractCompany | null {
		return this.repo.transform(id, RepoType.CONTRACT_COMPANY);
	}
}

@Pipe({
	name: 'alarisCurrency',
	standalone: true
})
export class AlarisCurrencyPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): Currency | null {
		return this.repo.transform(id, RepoType.CURRENCY);
	}
}

@Pipe({
	name: 'sender',
	standalone: true
})
export class SenderPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): SenderSubscription | null {
		return this.repo.transform(id, RepoType.SENDERS);
	}
}

@Pipe({
	name: 'paySystem',
	standalone: true
})
export class PaymentSystemPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): PaymentSystem | null {
		return this.repo.transform(id, RepoType.PAYMENT_SYSTEM);
	}
}

@Pipe({
	name: 'subGroup',
	standalone: true
})
export class SubscriptionGroupPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id?: Id<exist> | IdObject<exist>): SubscriptionGroup | null {
		return id !== undefined
			? this.repo.transform(id, RepoType.SUB_GROUP)
			: null;
	}
}
