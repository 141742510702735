import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';

@Injectable({
	providedIn: 'root'
})
export class SendersListService extends ExtendableRefBookService<SenderSubscription<exist>> implements AbstractCRUDService {
	readonly create = this.update;

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.sender');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.sender');
	}

	override load(): Observable<ReadResponse<SenderSubscription<exist>[]>> {
		return this.api.loader<ReadResponse<SenderSubscription<exist>[]>>(
			'Senders.Read', {}, this.loading$, this.errorNotifier
		)
			.pipe(map((resp) => {
				sortData(resp.Data, 'name');
				return super.process(resp);
			}));
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<SenderSubscription<exist>[]>> {
		return this.api.loader<ReadResponse<SenderSubscription<exist>[]>>('Senders.Read', params, this.loading$, this.errorNotifier);
	}

	requestSenderId(
		senderIds: SenderSubscription | SenderSubscription[]
	): Observable<CreateResponse<SenderSubscription<exist>>> {
		const Entities = Array.isArray(senderIds) ? senderIds : [senderIds];
		const params: RPCRequestParams = { Data: { Entities } };
		const notify = (response: CreateResponse<SenderSubscription<exist>>): void => {
			const message = this.langService.translate('notifications.actions.request', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<SenderSubscription<exist>>>(
			'Senders.Create', params, this.loading$, this.errorNotifier, notify
		).pipe(tap((resp) => {
			if ( resp.Success ) {
				this.refresh$.next();
			}
		}));
	}

	update(sender: SenderSubscription): Observable<CreateResponse<SenderSubscription>> {
		const notify = (response: CreateResponse<SenderSubscription>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					sender.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: sender.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<SenderSubscription>>(
			`Senders.${sender.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [sender] } },
			this.loading$, this.errorNotifier, notify
		).pipe(tap((resp) => {
			if ( resp.Success ) {
				this.refresh$.next();
			}
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<SenderSubscription>> {
		const params: DeleteRequest<SenderSubscription<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<SenderSubscription>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<SenderSubscription>>(
			'Senders.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap((resp) => {
			if ( resp.Success ) {
				this.refresh$.next();
			}
		}));
	}

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
}
