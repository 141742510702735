import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, switchMap, tap } from 'rxjs';

import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import {
	AvailableRequest,
	AvailableResponse,
	ClientSubscriptionsReadResponse,
	Package,
	PackCostRequest,
	PackCostResponse,
	Plan,
	Subscription,
	UnsubscribeRequest
} from '@campaign-portal/namespace/entities/subscriptions/specs';
import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse,
	UpdateResponse
} from '@campaign-portal/namespace/common/implementations';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	TableFiltersIndicator
} from '@campaign-portal/components-library';

export interface SavedFilters {
	table: TableFiltersIndicator;
	subscriptionType: SubscriptionType | null;
}

@Injectable({
	providedIn: 'root'
})
export class SubscriptionsService implements AbstractCRUDService {

	public readonly plansList$ = new BehaviorSubject<Plan<exist>[]>([]);
	public readonly packsList$ = new BehaviorSubject<Package<exist>[]>([]);
	public readonly plansMap$ = new BehaviorSubject<Map<exist, Plan<exist>>>(new Map());
	public readonly packsMap$ = new BehaviorSubject<Map<exist, Package<exist>>>(new Map());

	savedFilters: SavedFilters = {
		table: new Map(),
		subscriptionType: null
	};

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	readonly refresh$ = new Subject<void>();
	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		this.refresh$
			.pipe(switchMap(() => {
				return this.load();
			}))
			.subscribe();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.subscription');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.subscription');
	}

	get plansList(): Plan<number>[] {
		return this.plansList$.getValue();
	}

	get packsList(): Package<number>[] {
		return this.packsList$.getValue();
	}

	get plansMap(): Map<exist, Plan<exist>> {
		return this.plansMap$.getValue();
	}

	get packsMap(): Map<exist, Package<exist>> {
		return this.packsMap$.getValue();
	}

	clear(): void {
		this.plansList$.next([]);
		this.packsList$.next([]);
		this.plansMap$.next(new Map());
		this.packsMap$.next(new Map());
	}

	load(): Observable<ClientSubscriptionsReadResponse> {
		return this.api.loader<ClientSubscriptionsReadResponse>(
			'Subscriptions.Read', {}, this.loading$, this.errorNotifier
		)
			.pipe(map((resp) => {
				this.plansList$.next(resp.Data.plans as Plan<exist>[]);
				this.plansMap$.next(this.plansList.reduce((result, item) => {
					result.set(item.id, item);
					return result;
				}, new Map()));

				this.packsList$.next(resp.Data.packs as Package<exist>[]);
				this.packsMap$.next(this.packsList.reduce((result, item) => {
					result.set(item.id, item);
					return result;
				}, new Map()));
				return resp;
			}));
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Subscription<exist>[]>> {
		return this.api.loader<ClientSubscriptionsReadResponse>(
			'Subscriptions.Read', params, this.loading$, this.errorNotifier
		)
			.pipe(
				map(
					(resp) => {
						return ({
							...resp,
							Data: [...resp.Data.plans, ...resp.Data.packs]
						}) as ReadResponse<Subscription<exist>[]>;
					}
				)
			);
	}

	update(subscription: Subscription): Observable<CreateResponse<Subscription<exist>[]>> {
		const notify = (result: CreateResponse<Subscription<exist>[]>): void => {
			const message = this.langService.translate(
				subscription.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity,
					name: subscription.name
				});
			if ( result.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};

		return this.api.loader(
			`Subscriptions.${subscription.id ? 'Update' : 'Create'}`,
			{
				Data: {
					Entities: [subscription]
				}
			},
			this.loading$,
			this.errorNotifier,
			notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	unsubscribe(params: UnsubscribeRequest): Observable<UpdateResponse<Plan | Package>> {
		const notify = (response: UpdateResponse<Plan | Package>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					'notifications.actions.unsubscribe',
					{ entity: this.entity }
				);
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<UpdateResponse<Plan | Package>>(
			'Subscriptions.Unsubscribe', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Subscription<exist>>> {
		const params: DeleteRequest<Subscription<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<Subscription<exist>>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Subscription<exist>>>(
			'Subscriptions.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	packCost(params?: PackCostRequest): Observable<PackCostResponse> {
		return this.api.loader<PackCostResponse>('Subscriptions.PackCost', params, this.loading$, this.errorNotifier);
	}

	available(params: AvailableRequest): Observable<AvailableResponse> {
		return this.api.loader<AvailableResponse>('Subscriptions.Available', params, this.loading$, this.errorNotifier);
	}

	clearFilters(): void {
		this.savedFilters = {
			table: new Map(),
			subscriptionType: null
		};
	}
}
