import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisLanguageService,
	ChannelUtilsService,
	EnumsMapperService,
	TableEntityField,
	TableFilterData
} from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { DecisionMode, SubscriptionType, TrafficType } from '@campaign-portal/namespace/common/enums';
import { CurrencyService } from '@helpers/services/currency.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';
import { OwnerService } from '@helpers/services/owner.service';


@Injectable()
export class SubscriptionsFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly type: EntityField = {
		id: 0,
		name: 'columns.type',
		variable: 'type',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [
			{ name: this.lService.translate('enums.' + SubscriptionType.PLAN), value: SubscriptionType.PLAN },
			{ name: this.lService.translate('enums.' + SubscriptionType.PACK), value: SubscriptionType.PACK },
			{ name: this.lService.translate('enums.' + SubscriptionType.RESELLER), value: SubscriptionType.RESELLER }
		],
		readonly: true,
		filterType: FilterType.IN
	};
	readonly name: EntityField = {
		id: 1,
		name: 'columns.name',
		variable: 'name',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	readonly isActive: EntityField = {
		id: 2,
		name: 'columns.status',
		variable: 'isActive',
		required: true,
		type: InputComplexType.CHECKBOX,
		readonly: true,
		filterType: FilterType.EXACT
	};
	readonly trafficType: EntityField = {
		id: 3,
		name: 'columns.trafficType',
		variable: 'trafficType',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [
			{ name: this.lService.translate(this.cu.name(TrafficType.SMS)), value: TrafficType.SMS },
			{ name: this.lService.translate(this.cu.name(TrafficType.VIBER)), value: TrafficType.VIBER }
		],
		readonly: true,
		filterType: FilterType.IN
	};
	readonly currencyId: EntityField = {
		id: 4,
		name: 'columns.currency',
		variable: 'currencyId',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	readonly contractCompanies: EntityField = {
		id: 5,
		name: 'columns.cc',
		variable: 'contractCompanies',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	readonly partners: EntityField = {
		id: 6,
		name: 'columns.partner',
		variable: 'partners',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	readonly billingMode: EntityField = {
		id: 7,
		name: 'columns.billingMode',
		variable: 'billingMode',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: this.enums.transform(DecisionMode),
		readonly: true,
		filterType: FilterType.IN
	};
	readonly price: EntityField = {
		id: 7,
		name: 'gl.price',
		variable: 'packSettings',
		required: true,
		type: InputComplexType.RANGE,
		data: [],
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly personalFor: EntityField = {
		id: 8,
		name: 'fc.personalFor',
		variable: 'personalFor',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	readonly messagesTotal: EntityField = {
		id: 9,
		name: 'fc.numberMessages',
		variable: 'messagesTotal',
		required: true,
		type: InputComplexType.RANGE,
		data: [],
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly isPromo: EntityField = {
		id: 10,
		name: 'subscriptions.promo',
		variable: 'isPromo',
		required: false,
		type: InputComplexType.CHECKBOX,
		readonly: true,
		filterType: FilterType.EXACT
	};
	readonly groups: EntityField = {
		id: 11,
		name: 'subscriptions.groups.title',
		variable: 'groupId',
		required: false,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		filterType: FilterType.IN
	};

	readonly activeTill: EntityField = {
		id: 12,
		name: 'columns.activeTill',
		variable: 'activeTill',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};

	readonly headers: EntityField[] = [
		this.name,
		this.isActive,
		this.trafficType,
		this.currencyId,
		this.contractCompanies,
		this.partners,
		this.billingMode,
		this.personalFor,
		this.price,
		this.messagesTotal,
		this.isPromo
	];
	readonly trafficTypeList$ = new BehaviorSubject<ValueObject[]>([]);

	constructor(
		private readonly lService: AlarisLanguageService,
		private readonly cu: ChannelUtilsService,
		private readonly enums: EnumsMapperService,
		private readonly channelsService: ChannelsService,
		private readonly currencyService: CurrencyService,
		private readonly isOwner: OwnerService
	) {
		this.channelsService.list$
			.subscribe((list) => {
				const data = list.map((ch) => {
					return { name: this.lService.translate(ch.channelType), value: ch.channelType };
				});
				this.trafficTypeList$.next(data);
			});
	}

	get partnerSubscriptionHeaders(): TableEntityField[] {
		return this.getTableFields([this.name, this.type, this.currencyId, this.trafficType, this.price, this.activeTill]);
	}

	getGroupSubscriptionHeaders(type: SubscriptionType): TableEntityField[] {
		return type === SubscriptionType.PACK
			? this.getTableFields([this.name, this.billingMode, this.price])
			: this.getTableFields([this.name, this.billingMode, this.currencyId]);
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		if ( this.isOwner.is ) {
			this.headers.push(this.groups);
		}

		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}

	private getTableFields(fields: EntityField[]): TableEntityField[] {
		return fields.map(field => {
			const tableField = new TableEntityField(field);
			switch (field.variable) {
				case 'currencyId': {
					tableField.data$ = this.currencyService.list$ as BehaviorSubject<TableFilterData>;
					tableField.loading$ = this.currencyService.loading$;
					break;
				}
				case 'trafficType': {
					tableField.data$ = this.trafficTypeList$ as BehaviorSubject<TableFilterData>;
					tableField.loading$ = this.channelsService.loading$;
				}
			}
			return tableField;
		});
	}
}

