import { inject, Injectable, Type } from '@angular/core';

import { map, Observable, of } from 'rxjs';

import { AlarisDialogService } from '@campaign-portal/components-library';
import { LostDataDialogComponent } from '@helpers/can-deactivate/lost-data-dialog/lost-data-dialog.component';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateFn } from '@angular/router';


// https://betterprogramming.pub/angular-how-keep-user-from-lost-his-data-by-accidentally-leaving-the-page-before-submit-4eeb74420f0d

@Injectable({
	providedIn: 'root'
})
export class CanDeactivateGuardService {

	constructor(public readonly dialog: AlarisDialogService) {
	}

	canDeactivate(
		component: CanDeactivateComponent
	): Observable<boolean> {
		if ( component.canDeactivate() ) {
			return of(true);
		}
		return this.lostDataDialog();
	}

	lostDataDialog(component?: Type<unknown>): Observable<boolean> {
		return this.dialog.open<boolean>(component ?? LostDataDialogComponent, { disableClose: false }).closed
			.pipe(map((resp) => {
				return !!resp;
			}));
	}
}

export const CanDeactivateGuard: CanDeactivateFn<CanDeactivateComponent> = (
	component: CanDeactivateComponent
): Observable<boolean> | boolean => {
	return inject(CanDeactivateGuardService).canDeactivate(component);
};
