import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams,
	TranslateDefaultParser,
	TranslateLoader,
	TranslateModule,
	TranslateParser
} from '@ngx-translate/core';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Injectable, LOCALE_ID } from '@angular/core';
import {
	ACCOUNT_SETTINGS_CONFIG,
	ACCOUNT_SETTINGS_SERVICE_INJECTOR,
	AccountSettingsConfig,
	AlarisAccountSettingsService,
	AlarisApiService,
	AlarisAuthService,
	AlarisBalanceService,
	AlarisConfigService,
	AlarisErrorInterceptor,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisPaymentMethodsService,
	AlarisProfileService,
	AlarisResponseInterceptor,
	AlarisRpcInterceptor,
	AlarisSignUpService,
	AlarisTableStateStore,
	AlarisToasterModule,
	AUTH_SERVICE_INJECTOR,
	BALANCE_SERVICE_INJECTOR,
	BindEventPlugin,
	CAPTCHA_CONFIG,
	CaptchaConfig,
	CONFIG_SERVICE_INJECTOR,
	DATE_FILTER_END_OF_THE_DAY_INJECTOR,
	DAY_TYPE_HANDLER,
	English,
	ENVIRONMENT,
	FILES_SERVICE_INJECTOR,
	ICONS_CONFIG,
	LANGUAGE_CONFIG,
	LANGUAGE_SERVICE_INJECTOR,
	LanguageConfig,
	LocaleIdService,
	LOCALSTORAGE_SERVICE_INJECTOR,
	MENU_MQ_SERVICE_INJECTOR,
	MENU_MQ_WIDTH_VALUE,
	MenuSidebarService,
	PAYMENT_METHODS_SERVICE_INJECTOR,
	PROFILE_CONFIG,
	PROFILE_SERVICE_INJECTOR,
	ProfileConfig,
	REPO_SERVICE_INJECTOR,
	Russian,
	SIGN_IN_CONFIG,
	SIGN_IN_SERVICE_INJECTOR,
	SIGN_UP_CONFIG,
	SIGN_UP_SERVICE_INJECTOR,
	SignInConfig,
	SignUpConfig,
	Spain,
	TABLE_STATE_STORE_INJECTOR, Turkish
} from '@campaign-portal/components-library';
import { RoleType } from '@campaign-portal/namespace/common/enums';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { RepoService } from '@helpers/repo/repo.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MccmncPipe } from './pages/settings/mccmnc/mccmnc.pipe';

import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';

import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app.routing';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';

/* Translations */
export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n_lib/app.', suffix: '.json?' + environment.version },
		{ prefix: './assets/i18n/app.', suffix: '.json?' + environment.version }
	]);
}

export class MissingTranslationService implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams): string {
		console.warn(`WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`);
		return params.key;
	}
}

@Injectable()
export class TranslateParserService extends TranslateDefaultParser {

	constructor() {
		super();
	}

	override getValue(target: any, key: string): any {
		const result = super.getValue(target, key);
		return typeof result === 'object' ? key : result;
	}

}

/* End of Translations */

/* forRoot() Configs */
const translateConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend]
	},
	missingTranslationHandler: {
		provide: MissingTranslationHandler, useClass: MissingTranslationService
	},
	parser: { provide: TranslateParser, useClass: TranslateParserService },
	// if we set `defaultLanguage` to 'en' right here - we force loading en.json with translations
	// so missing translations will be used from en.file
	defaultLanguage: ''
};

const signInConfig: SignInConfig = {
	url: '',
	supportEmail: '',
	signInMethod: 'Auth.SignIn',
	refreshTokenMethod: 'Auth.Refresh',
	resetPasswordMethod: 'Auth.ResetPassword',
	verify2FACodeMethod: 'Auth.Verify2FACode',
	is2FA: false,
	signUpLink: '/sign-up'
};

const signUpConfig: SignUpConfig = {
	url: '',
	signInLink: '/sign-in',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: '',
	signUpMethod: 'Auth.SignUp',
	registrationFieldsMethod: 'RegistrationFields.Read',
	generatePasswordMethod: 'Users.GeneratePassword'
};

const captchaConfig: CaptchaConfig = {
	baseUrl: 'captcha'
};

const profileConfig: ProfileConfig = {
	profileRoute: '/account-settings',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: ''
};

const accountSettingsConfig: AccountSettingsConfig = {
	resetPasswordMethod: 'Users.ResetPassword',
	userUpdateMethod: 'Users.EditPersonalData',
	verify2FACodeMethod: 'Auth.Verify2FACodeForReset',
	reset2FACodeMethod: 'Auth.GetQRForResetCodeForPortal',
	partnersReadMethod: 'Partners.Read',
	role: RoleType.ADMIN,
	is2FA: false
};

const languageConfig: LanguageConfig = { languages: [English, Spain], currentLanguage: English };
/* End of forRoot() Configs */


/* App Config */
export function loadConfig(
	appConfig: AlarisConfigService,
	store: AlarisLocalStorageService,
	translate: AlarisLanguageService
): () => Promise<void> {
	return (): Promise<void> => appConfig.load().then(
		() => {
			signInConfig.url = appConfig.api;
			signInConfig.supportEmail = appConfig.supportEmail;
			signInConfig.is2FA = appConfig.is2FA;

			accountSettingsConfig.is2FA = appConfig.is2FA;

			signUpConfig.url = appConfig.api;
			signUpConfig.termsUrl = appConfig.termsUrl;
			signUpConfig.privacyUrl = appConfig.privacyUrl;
			signUpConfig.supportEmail = appConfig.supportEmail;

			profileConfig.termsUrl = appConfig.termsUrl;
			profileConfig.privacyUrl = appConfig.privacyUrl;
			profileConfig.supportEmail = appConfig.supportEmail;

			languageConfig.languages = appConfig.getLanguages();

			const lang = store.get('lang') ?? 'en';
			translateConfig.defaultLanguage = lang;
			registerLocaleData(localeEn, English.id, localeEnExtra);
			registerLocaleData(localeEs, Spain.id, localeEsExtra);
			registerLocaleData(localeRu, Russian.id, localeRuExtra);
			registerLocaleData(localeTr, Turkish.id, localeTrExtra);
			// https://mcvendrell.medium.com/configuring-ngx-translate-to-load-at-startup-in-angular-1995e7dd6fcc
			translate.set(lang);
		}
	);
}

/* End of App Config */

export const APP_CONFIG: ApplicationConfig = {
	providers: [
		importProvidersFrom(
			AlarisToasterModule.forRoot(),
			TranslateModule.forRoot(translateConfig)
		),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(APP_ROUTES),

		AlarisConfigService,
		AlarisLocalStorageService,
		AlarisApiService,
		AlarisAuthService,
		AlarisSignUpService,
		AlarisProfileService,
		AlarisLanguageService,
		AlarisFilesService,
		AlarisBalanceService,
		AlarisPaymentMethodsService,
		AlarisAccountSettingsService,
		AlarisTableStateStore,

		{ provide: ENVIRONMENT, useValue: environment },
		{ provide: LOCALE_ID, useClass: LocaleIdService },
		{ provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { timezone: '+0', dateFormat: 'd MMM y, HH:mm' } },
		{ provide: EVENT_MANAGER_PLUGINS, useClass: BindEventPlugin, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [
				AlarisConfigService,
				AlarisLocalStorageService,
				AlarisLanguageService
			],
			multi: true
		},

		{ provide: CAPTCHA_CONFIG, useValue: captchaConfig },
		{ provide: LANGUAGE_CONFIG, useValue: languageConfig },
		{ provide: PROFILE_CONFIG, useValue: profileConfig },
		{ provide: ICONS_CONFIG, useValue: { baseUrl: '/assets/icons' } },
		{ provide: ACCOUNT_SETTINGS_CONFIG, useValue: accountSettingsConfig },
		{ provide: SIGN_IN_CONFIG, useValue: signInConfig },
		{ provide: SIGN_UP_CONFIG, useValue: signUpConfig },

		{ provide: LOCALSTORAGE_SERVICE_INJECTOR, useExisting: AlarisLocalStorageService },
		{ provide: CONFIG_SERVICE_INJECTOR, useExisting: AlarisConfigService },
		{ provide: REPO_SERVICE_INJECTOR, useExisting: RepoService },
		{ provide: AUTH_SERVICE_INJECTOR, useExisting: AlarisAuthService },
		{ provide: SIGN_IN_SERVICE_INJECTOR, useExisting: AlarisAuthService },
		{ provide: SIGN_UP_SERVICE_INJECTOR, useExisting: AlarisSignUpService },
		{ provide: PROFILE_SERVICE_INJECTOR, useExisting: AlarisProfileService },
		{ provide: LANGUAGE_SERVICE_INJECTOR, useExisting: AlarisLanguageService },
		{ provide: FILES_SERVICE_INJECTOR, useExisting: AlarisFilesService },
		{ provide: BALANCE_SERVICE_INJECTOR, useExisting: AlarisBalanceService },
		{ provide: PAYMENT_METHODS_SERVICE_INJECTOR, useExisting: AlarisPaymentMethodsService },
		{ provide: ACCOUNT_SETTINGS_SERVICE_INJECTOR, useExisting: AlarisAccountSettingsService },
		{ provide: TABLE_STATE_STORE_INJECTOR, useExisting: AlarisTableStateStore },

		{ provide: HTTP_INTERCEPTORS, useClass: AlarisRpcInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisErrorInterceptor, multi: true },

		{ provide: DAY_TYPE_HANDLER, useValue: (): string => 'weekday' },
		{ provide: DATE_FILTER_END_OF_THE_DAY_INJECTOR, useValue: true },
		{ provide: MENU_MQ_SERVICE_INJECTOR, useClass: MenuSidebarService },
		{ provide: MENU_MQ_WIDTH_VALUE, useValue: '(max-width: 1365px)' },

		MccmncPipe
	]
};
