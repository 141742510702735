import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ActivateService } from './activate.service';

@Injectable({
	providedIn: 'root'
})
export class ActivateGuardService {

	constructor(
		private readonly activateService: ActivateService,
		private readonly router: Router
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot
		// state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if ( route.queryParams.key ) {
			this.activateService.token = route.queryParams.key;
			return true;
		} else {
			this.router.navigate(['']).then();
		}
		return true;
	}

}

export const ActivateGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(ActivateGuardService).canActivate(route);
};
