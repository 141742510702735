import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
	AlarisApiService,
	AlarisLanguageService,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { exist } from '@campaign-portal/namespace/common/id';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';


@Injectable({
	providedIn: 'root'
})
export class CurrencyService extends ExtendableRefBookService<Currency<exist>> {

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get title(): string {
		return this.langService.translate('notifications.titles.currency');
	}
	
	override load(): Observable<ReadResponse<Currency<exist>[]>> {
		return this.api.loader<ReadResponse<Currency<exist>[]>>('Currency.Read', {}, this.loading$)
			.pipe(map((resp) => {
				sortData(resp.Data, 'name');
				return super.process(resp);
			}));
	}

}
