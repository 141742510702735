import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { OwnerService } from '@helpers/services/owner.service';
import {
	AlarisProfileService,
	PROFILE_SERVICE_INJECTOR,
	AlarisLinkComponent,
	AlarisButtonComponent,
	AlarisIconDirective
} from '@campaign-portal/components-library';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-empty-table',
	templateUrl: './empty-table.component.html',
	styleUrls: ['./empty-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [AlarisLinkComponent, AlarisButtonComponent, AlarisIconDirective, TranslateModule]
})
export class EmptyTableComponent implements OnChanges {
	@Input() type: 'contract-company' |
		'edr-export' |
		'edr-export-task' |
		'edr-export-with-filter' |
		'endpoint' |
		'import-history' |
		'link-domains' |
		'link-domains-with-filter' |
		'mccmnc' |
		'partner' |
		'payments' |
		'payment-systems' |
		'portals' |
		'products' |
		'rates' |
		'transactions' |
		'users' |
		'user-partner' |
		'sender-events-owner' |
		'sender-events-reseller' |
		'sender-list-owner' |
		'sender-list-reseller' |
		'sender-subscription' |
		'no-senders-to-subscribe' |
		'subscribers' |
		'subscription-groups' |
		'subscription-groups-with-filter' |
		'subscription-rates' |
		'subscriptions' = 'subscriptions';
	@Input() showImg = true;
	@Input() disabled = false;
	@Output() readonly newMCCMNC = new EventEmitter();
	@Output() readonly newPayment = new EventEmitter();
	@Output() readonly newSender = new EventEmitter();
	@Output() readonly newSenderSubscriptions = new EventEmitter();
	@Output() readonly newContractCompany = new EventEmitter();
	@Output() readonly newUser = new EventEmitter();
	@Output() readonly clearFilter = new EventEmitter();
	@Output() readonly newPaymentSystem = new EventEmitter();
	@Output() readonly newEndpoint = new EventEmitter();
	@Output() readonly newProduct = new EventEmitter();
	@Output() readonly newPartner = new EventEmitter();
	@Output() readonly importRates = new EventEmitter();
	@Output() readonly newSubscriptions = new EventEmitter();
	@Output() readonly newSubscriptionGroup = new EventEmitter();
	@Output() readonly newLinkDomain = new EventEmitter();

	title = '';
	subTitle = '';
	subTitleLink = '';
	buttonText = '';
	optionalButtonText = '';
	icon = '';

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService,
		private readonly isOwner: OwnerService
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.type ) {
			switch (this.type) {
				case 'mccmnc':
					this.title = 'mccmnc.noFound';
					this.subTitle = 'mccmnc.noFoundNote';
					this.buttonText = 'mccmnc.addEntry';
					this.icon = 'icon-plus';
					break;
				case 'transactions':
					this.title = 'finance.noFound';
					break;
				case 'payments':
					this.title = 'payments.noFound';
					this.subTitle = 'payments.noFoundNote';
					this.buttonText = 'payments.addPayment';
					this.icon = 'icon-plus';
					break;
				case 'sender-events-owner':
					this.title = 'senders.noEvents';
					this.subTitle = 'senders.noEventsNote';
					break;
				case 'sender-events-reseller':
					this.title = 'senders.noEvents';
					this.subTitle = `senders.noResellerEventsNote`;
					break;
				case 'sender-list-owner':
					this.title = 'senders.noSenders';
					if ( this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ) {
						this.subTitle = 'senders.noSendersNote';
						this.buttonText = 'senders.addSenderID';
						this.icon = 'icon-plus';
					}
					break;
				case 'sender-list-reseller':
					this.title = 'senders.noSenders';
					if ( this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ) {
						this.subTitle = `senders.noResellerSendersNote`;
						this.buttonText = 'senders.requestSenderID';
						this.icon = 'icon-plus';
					}
					break;
				case 'sender-subscription':
					this.title = 'senders.noSubFound';
					this.subTitle = 'senders.noSubFoundNote';
					this.buttonText = 'senders.addSubscriptions';
					this.icon = 'icon-plus';
					break;
				case 'contract-company':
					this.title = 'contract-companies.noFound';
					this.subTitle = 'contract-companies.noFoundNote';
					this.buttonText = 'contract-companies.add';
					this.icon = 'icon-plus';
					break;
				case 'portals':
					this.title = 'partners.noCC';
					this.subTitle = '';
					this.buttonText = 'contract-companies.createEntry';
					this.icon = '';
					break;
				case 'users':
					this.title = 'users.noFound';
					this.subTitle = 'users.noFoundNote';
					this.buttonText = 'users.addEntry';
					this.icon = 'icon-plus';
					break;
				case 'user-partner':
					this.title = 'partners.noPartners';
					this.subTitle = 'users.noPartnersNote';
					this.buttonText = 'partners.addEntry';
					this.optionalButtonText = 'users.createColleague';
					this.icon = 'icon-plus';
					break;
				case 'edr-export':
					this.title = 'statistics.noEdrFound';
					this.subTitle = 'statistics.noEdrFoundNote';
					break;
				case 'edr-export-with-filter':
					this.title = 'statistics.noEdrFound';
					this.buttonText = 'gl.clearFilter';
					this.icon = 'icon-close';
					break;
				case 'edr-export-task':
					this.title = 'statistics.noEdrTaskFound';
					this.subTitle = 'statistics.noEdrTaskFoundNote';
					break;
				case 'payment-systems':
					this.title = 'payment-systems.noPaymentSystemFound';
					this.subTitle = 'payment-systems.noPaymentSystemFoundNote';
					this.buttonText = 'payment-systems.add';
					this.icon = 'icon-plus';
					break;
				case 'endpoint':
					this.title = 'endpoints.createEndpoint';
					this.subTitle = 'endpoints.createEndpointNote';
					this.buttonText = 'endpoints.add';
					this.icon = 'icon-plus';
					break;
				case 'partner':
					this.title = 'partners.noPartners';
					this.subTitle = 'partners.noPartnersNote';
					this.buttonText = 'partners.addEntry';
					this.icon = 'icon-plus';
					break;
				case 'products':
					this.title = `products.${this.isOwner.is ? 'createProduct' : 'noResellingPlan'}`;
					this.subTitle = `products.${this.isOwner.is ? 'createProductNote' : 'contactAdministrator'}`;
					this.buttonText = this.isOwner.is ? 'products.addEntry' : '';
					this.icon = 'icon-plus';
					break;
				case 'rates':
					this.title = 'rates.noRates';
					this.subTitle = 'rates.noRatesNote';
					this.buttonText = this.isOwner.is ? 'rates.importRates' : '';
					this.icon = 'icon-plus';
					break;
				case 'subscriptions':
					this.title = 'subscriptions.noSubscriptions';
					this.subTitle = 'subscriptions.noSubscriptionsNote';
					this.buttonText = 'subscriptions.addEntry';
					this.icon = 'icon-plus';
					break;
				case 'no-senders-to-subscribe':
					this.title = 'subscriptions.noSubscriptionsToBeSubscribed';
					this.subTitle = 'subscriptions.noSubscriptionsToBeSubscribedNote';
					this.buttonText = '';
					this.icon = '';
					break;
				case 'subscribers':
					this.title = 'subscriptions.noFoundSubscribers';
					break;
				case 'subscription-groups':
					this.title = 'subscriptions.groups.noGroups';
					this.subTitle = 'subscriptions.groups.noGroupsNote';
					this.buttonText = 'subscriptions.groups.createGroup';
					this.icon = 'icon-plus';
					break;
				case 'subscription-groups-with-filter':
					this.subTitleLink = 'gl.clearFilter';
					this.subTitle = 'gl.gl.noFilteredEntities';
					break;
				case 'subscription-rates':
					this.title = 'subscriptions.noRates';
					this.subTitle = 'subscriptions.noRatesNote';
					break;
				case 'import-history':
					this.title = 'rates.noImport';
					this.subTitle = 'rates.noImportNote';
					this.buttonText = 'rates.importRates';
					this.icon = 'icon-plus';
					break;
				case 'link-domains':
					this.title = 'urlShortener.noDomains'
					this.subTitle = 'urlShortener.noDomainsNote'
					this.buttonText = 'urlShortener.createDomain';
					this.icon = 'icon-plus';
					break;
				case 'link-domains-with-filter':
					this.subTitleLink = 'gl.clearFilter';
					this.subTitle = 'gl.noFilteredEntities'
					break;
				default:
					break;
			}
		}
	}

	clicked(): void {
		switch (this.type) {
			case 'mccmnc':
				this.newMCCMNC.emit(undefined);
				break;
			case 'payments':
				this.newPayment.emit(undefined);
				break;
			case 'sender-list-owner':
			case 'sender-list-reseller':
				this.newSender.emit(undefined);
				break;
			case 'contract-company':
			case 'portals':
				this.newContractCompany.emit(undefined);
				break;
			case 'users':
				this.newUser.emit(undefined);
				break;
			case 'edr-export-with-filter':
				this.clearFilter.emit(undefined);
				break;
			case 'payment-systems':
				this.newPaymentSystem.emit(undefined);
				break;
			case 'endpoint':
				this.newEndpoint.emit(undefined);
				break;
			case 'products':
				this.newProduct.emit(undefined);
				break;
			case 'partner':
			case 'user-partner':
				this.newPartner.emit(undefined);
				break;
			case 'rates':
			case 'import-history':
				this.importRates.emit(undefined);
				break;
			case 'sender-subscription':
				this.newSenderSubscriptions.emit(undefined);
				break;
			case 'subscriptions':
				this.newSubscriptions.emit(undefined);
				break;
			case 'subscription-groups':
				this.newSubscriptionGroup.emit(undefined);
				break;
			case 'link-domains':
				this.newLinkDomain.emit(undefined);
				break;
			default:
				break;
		}
	}

	optionalButtonClicked(): void {
		switch (this.type) {
			case 'user-partner':
				this.newUser.emit(undefined);
				break;
			default:
				break;
		}
	}

	subTitleLinkClicked(): void {
		switch (this.type) {
			case 'subscription-groups-with-filter':
			case 'link-domains-with-filter':
				this.clearFilter.emit();
				break;
			default:
				break;
		}
	}
}
