import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of } from 'rxjs';

import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';

import { PartnersService } from '../../pages/partners/partners.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { SendersListService } from '../../pages/senders/senders-list/senders-list.service';
import { PaymentSystemsService } from '../../pages/settings/payment-systems/payment-systems.service';
import { ContractCompaniesService } from '../../pages/settings/contract-companies/contract-companies.service';
import { SubscriptionsService } from '../../pages/subscriptions/subscriptions-list/subscriptions.service';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { AlarisBalanceService, AlarisProfileService } from '@campaign-portal/components-library';
import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { SubscriptionGroupsService } from '../../pages/subscriptions/subscription-groups/subscription-groups.service';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';

export enum RepoType {
	CHANNELS = 'ch',
	SENDERS = 'sender',
	CURRENCY = 'currency',
	PARTNER = 'partner',
	CONTRACT_COMPANY = 'cc',
	PAYMENT_SYSTEM = 'paySystem',
	PRODUCT = 'product',
	SUB_GROUP = 'subGroup'
}

@Injectable({
	providedIn: 'root'
})
export class RepoService {
	public readonly loaded$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly bs: AlarisBalanceService,
		private readonly channelsService: ChannelsService,
		private readonly currenciesService: CurrencyService,
		private readonly countriesNetworksService: CountriesNetworksService,
		private readonly partnersService: PartnersService,
		private readonly sendersService: SendersListService,
		private readonly paymentSystemsService: PaymentSystemsService,
		private readonly ccService: ContractCompaniesService,
		private readonly vsService: VendorSubscriptionsService,
		private readonly subService: SubscriptionsService,
		private readonly subGroupsService: SubscriptionGroupsService,
		private readonly profile: AlarisProfileService
	) {
	}

	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.CHANNELS): Channel | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.SENDERS): SenderSubscription | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.CURRENCY): Currency | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.PARTNER): Partner | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.CONTRACT_COMPANY): ContractCompany | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.PAYMENT_SYSTEM): PaymentSystem | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.PRODUCT): Subscription | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.SUB_GROUP): SubscriptionGroup | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType): IdObject | null {
		switch (type) {
			case RepoType.CHANNELS:
				return this.find<Channel>(id, this.channelsService.map);
			case RepoType.CONTRACT_COMPANY:
				return this.find<ContractCompany>(id, this.ccService.map);
			case RepoType.PARTNER:
				return this.find<Partner>(id, this.partnersService.map);
			case RepoType.PRODUCT:
				return this.find<Subscription>(id, this.vsService.map);
			case RepoType.CURRENCY:
				return this.find<Currency>(id, this.currenciesService.map);
			case RepoType.SENDERS:
				return this.find<SenderSubscription>(id, this.sendersService.map);
			case RepoType.PAYMENT_SYSTEM:
				return this.find<PaymentSystem>(id, this.paymentSystemsService.map);
			case RepoType.SUB_GROUP:
				return this.find<SubscriptionGroup>(id, this.subGroupsService.map);
			default:
				return null;
		}
	}

	public reset(): void {
		this.loaded$.next(false);

		this.ccService.clear();

		this.countriesNetworksService.clear();

		this.partnersService.clear();

		this.channelsService.clear();

		this.currenciesService.clear();

		this.sendersService.clear();

		this.paymentSystemsService.clear();

		this.vsService.clear();

		this.subService.clear();

		this.loaded$.next(false);
	}

	public load(): Observable<unknown> {
		const repositoriesRequests: Observable<unknown>[] = [
			this.bs.read(),

			this.countriesNetworksService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),

			this.ccService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),

			this.partnersService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),

			this.channelsService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),

			this.currenciesService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),

			this.sendersService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),

			this.paymentSystemsService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			}))
			// this.ratesImportHistoryService.read({
			// 	Filters: [{
			// 		Field: 'subscriptionId',
			// 		Type: FilterType.EXACT,
			// 		Value: SubscriptionType.VENDOR,
			// 		Property: 'type'
			// 	}]
			// }).pipe(catchError((resp) => {
			// 	resp.Data = [];
			// 	return of(resp);
			// })),

		];

		if ( this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_R]) ) {
			repositoriesRequests.push(
				this.vsService.load().pipe(catchError((resp) => {
					resp.Data = [];
					return of(resp);
				}))
			);
		}

		if ( this.profile.allowed([AP_PERMISSIONS.SUBSCR_R]) ) {
			repositoriesRequests.push(
				this.subService.load().pipe(catchError((resp) => {
					resp.Data = [];
					return of(resp);
				}))
			);

			repositoriesRequests.push(
				this.subGroupsService.load().pipe(catchError((resp) => {
					resp.Data = [];
					return of(resp);
				}))
			);
		}

		return forkJoin(repositoriesRequests).pipe(
			map((resp) => {
				this.loaded$.next(true);
				return resp;
			})
		);

	}

	private find<T>(
		ids: Id<exist> | IdObject<exist>,
		dataMap: Map<number, T>
	): T | null {
		if ( typeof ids === 'number' ) {
			const found = dataMap.get(ids);
			return found ?? null;
		} else if ( typeof ids === 'object' && !Array.isArray(ids) ) {
			const found = dataMap.get(ids.id);
			return found ?? null;
		}
		return null;
	}
}
