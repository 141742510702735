import { inject, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Data, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlarisAuthService, AlarisProfileService, PROFILE_SERVICE_INJECTOR } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService {

	readonly currentRouteData = new BehaviorSubject<Data | null>(null);
	readonly parentRoute = new BehaviorSubject<ActivatedRouteSnapshot | null>(null);

	constructor(
		private readonly authService: AlarisAuthService,
		private readonly router: Router,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot
		// state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
		if ( this.authService.logged ) {
			this.currentRouteData.next(route.data);
			this.parentRoute.next(route.parent);
			const { permissions, permissionComparison } = route.data;
			if ( permissions ) {
				return this.profile.allowed(permissions, permissionComparison)
					? true
					: this.router.parseUrl('');
			}
			return true;
		}

		this.currentRouteData.next(null);
		this.parentRoute.next(null);
		this.authService.logOut();
		return false;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot
		// state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(
			childRoute
			// state
		);
	}

}

export const AuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): Observable<boolean> | Promise<boolean> | boolean | UrlTree => {
	return inject(AuthGuardService).canActivate(route);
};
